export const environment = {
  production: false,

  clientName: 'MULTICARGA DEL MAGDALENA - AGP',
  apiGatewayFront: 'https://qa-siesaagp.multicarga.com.co/',
  apiGatewayBackOffice: 'https://qa-siesaagp.multicarga.com.co/',
  apiUrl: 'https://qa-siesaagp.multicarga.com.co/',
  urlPath: 'https://qa-siesaagp.multicarga.com.co/',
  customCss: false,
  // Whether or not to enable debug mode
  // Setting this to false will disable console output
  enableDebug: false,
  // siteKeyCaptcha: '6LdLtBsgAAAAAJksLT4W-TI-oRed7Uk9B8Y-EECB', DEMO
  // siteKeyCaptcha: '6LfL5fkfAAAAALmrMTjEg18wnQm5uR3ZNbBy-pL7' ZX

  siteKeyCaptcha: '6Lehzp8mAAAAALEsvK6j7VZBwg7wgwd8iZBdQwLU',

  googleAnalyticsCode: 'UA-140785247-1',
};